// iPhone, min-width: 320px
// mobile, min-width: 480px
// tablet, min-width: 768px
// laptop, min-width: 992px
// desktop, min-width: 1200px
/* Available Font Variants

Regular	"Montserrat"	400	normal

Italic	"Montserrat"	400	italic

Bold	"Montserrat"	700	normal

Bold Italic	"Montserrat"	700	italic

 */

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'mobile' {
    @media (min-width: 480px) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin button-style($color1, $color2) {
  background: linear-gradient(to right, $color1, $color2);
  transform: none;
  transition: 0.25s;

  &:hover {
    transform: scale(1.02);
  }
}

@mixin center {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* STYLE USAGE:

Preferred CSS structure: Elements are sorted alphabetically on each line.
	element {
	width:10px; height:50px; // height, width, positioning, and z-index go here (width/height trump a-z order, ie they're always first)
	margin-top:1px; // margins and padding go here
	border:1px solid @bg-accent-color; // borders, backgrounds, box-shadows go here
	color:#fff; // all typography goes here
	transition: .2s all; // transitions, miscellaneous go here
	}

If there's only one line of elements, put everything on one line:

	element { rules go here; }

Otherwise, put brackets + element on separate lines than the CSS rules.

	element {
	rules go here;
	second line of rules;
	}

 */

/* -------------------------- Basic CSS reset -------------------------- */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(#000000, 0);
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body,
html,
div,
blockquote,
img,
figure,
p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
ol,
li,
dl,
dt,
dd,
form,
a,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

input[type='search'] {
  -webkit-appearance: textfield;
}

img {
  border: 0;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

blockquote,
q {
  quotes: '' '';
}

.both {
  clear: both;
}

br.both {
  clear: both;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

/* -------------------------- clearfix -------------------------- */
.cf:after,
.pw:after,
[data-st='st-row']:after,
[data-st='st-row'] > *:after,
section:after {
  content: '';
  display: table;
  clear: both;
}

/* -------------------------- Simple CSS grid (Saratoga) -------------------------- */
[data-st='st-row'] {
  clear: both;
  margin-bottom: 10%;
  & > *:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }
}

[data-st='st-row']:last-child {
  margin-bottom: 0;
}

[data-st='st-one'],
[data-st='st-two'] {
  width: 46.25%;
  margin-right: 7.5%;
  margin-bottom: 10%;
  float: left;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:last-child,
  &:nth-last-child(2) {
    margin-bottom: 0;
  }
}

[data-st='st-three'],
[data-st='st-four'],
[data-st='st-five'],
[data-st='st-six'],
[data-st='st-seven'],
[data-st='st-eight'],
[data-st='st-nine'],
[data-st='st-ten'],
[data-st='st-eleven'],
[data-st='st-twelve'] {
  width: 100%;
  float: left;
  margin-bottom: 10%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .App-wrap {
    flex: 1;
  }
}

.pw,
.fw,
.App-wrapper {
  width: 84%;
  max-width: 1400px;
  padding: 10% 0;
  padding-bottom: 0 !important;
  margin: 0 auto 0;
  &:last-child {
    margin-bottom: 10%;
  }
}

.fw {
  max-width: none;
}

.tight .pw {
  max-width: 1024px;
}

@media screen and (min-width: 40rem) {
  .pw,
  .fw,
  .App-wrapper {
    width: 90%;
    padding: 5% 0;
    margin-bottom: 5%;
    &:last-child {
      margin-bottom: 5.6%;
    }
  }
  [data-st='st-row'] {
    margin-bottom: 5%;
  }

  [data-st='st-one'] {
    width: 20.8%;
    margin: 0 5.6% 5.6% 0;
    &:nth-child(2n) {
      margin-right: 5.6%;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:nth-last-child(3),
    &:nth-last-child(4) {
      margin-bottom: 0;
    }
  }

  [data-st='st-two'] {
    width: 47.2%;
    margin: 0 5.6% 5.6% 0;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  [data-st='st-three'],
  [data-st='st-four'],
  [data-st='st-five'],
  [data-st='st-six'],
  [data-st='st-seven'],
  [data-st='st-eight'],
  [data-st='st-nine'],
  [data-st='st-ten'],
  [data-st='st-eleven'],
  [data-st='st-twelve'] {
    margin-bottom: 5.6%;
  }
}

@media screen and (min-width: 48rem) {
  [data-st='st-row'] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: stretch;
  }

  [data-st='st-one'] {
    width: 3.2%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-two'] {
    width: 12%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-three'] {
    width: 20.8%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-four'] {
    width: 29.6%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-five'] {
    width: 38.4%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-six'] {
    width: 47.2%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-seven'] {
    width: 56%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-eight'] {
    width: 64.8%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-nine'] {
    width: 73.6%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-ten'] {
    width: 82.4%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-eleven'] {
    width: 91.2%;
    margin: 0 5.6% 0 0;
  }
  [data-st='st-twelve'] {
    width: 100%;
  }

  [data-st='st-one']:nth-child(2n),
  [data-st='st-two']:nth-child(2n),
  [data-st='st-three']:nth-child(2n) {
    margin-right: 5.6%;
  }
  [data-st='st-two']:nth-child(6n) {
    margin-right: 0;
  }
  [data-st='st-two']:nth-child(6n + 1) {
    clear: left;
  }
}

@media screen and (min-width: 84rem) {
  .pw,
  .fw,
  .App-wrapper {
    width: 92%;
    padding: 4% 0;
    margin-bottom: 4%;
    &:last-child {
      margin-bottom: 4%;
    }
  }
  [data-st='st-row'] {
    margin-bottom: 4%;
  }

  [data-st='st-one'] {
    width: 4.667%;
    margin: 0 4% 0 0;
  }
  [data-st='st-two'] {
    width: 13.334%;
    margin: 0 4% 0 0;
  }
  [data-st='st-three'] {
    width: 22%;
    margin: 0 4% 0 0;
  }
  [data-st='st-four'] {
    width: 30.667%;
    margin: 0 4% 0 0;
  }
  [data-st='st-five'] {
    width: 39.334%;
    margin: 0 4% 0 0;
  }
  [data-st='st-six'] {
    width: 48%;
    margin: 0 4% 0 0;
  }
  [data-st='st-seven'] {
    width: 56.667%;
    margin: 0 4% 0 0;
  }
  [data-st='st-eight'] {
    width: 65.334%;
    margin: 0 4% 0 0;
  }
  [data-st='st-nine'] {
    width: 74%;
    margin: 0 4% 0 0;
  }
  [data-st='st-ten'] {
    width: 82.667%;
    margin: 0 4% 0 0;
  }
  [data-st='st-eleven'] {
    width: 91.334%;
    margin: 0 4% 0 0;
  }
  [data-st='st-twelve'] {
    width: 100%;
  }

  [data-st='st-one']:nth-child(2n),
  [data-st='st-two']:nth-child(2n),
  [data-st='st-three']:nth-child(2n),
  [data-st='st-one']:nth-child(6n) {
    margin-right: 4%;
  }
  [data-st='st-one']:last-child,
  [data-st='st-two']:last-child,
  [data-st='st-three']:last-child,
  [data-st='st-one']:last-child,
  [data-st='st-four']:last-child {
    margin-right: 0;
  }
  [data-st='st-two']:nth-child(6n) {
    margin-right: 0;
  }
}

/* end Saratoga GRID */

/* -------------------------- THEMING -------------------------- */

// Set theme-specific variables in ./themes.css

/* Colors */
$blue-main: #33c7ff;
$blue-dark: #1fb8ff;
$blue-darker: #154872;
$black: var(--color-black);
$black-light: var(--color-black-light);
$gold: var(--color-gold);
$gold-light: var(--color-gold-light);
$header-text: var(--color-header-text);
$paragraph-text: var(--color-paragraph-text);
$secondary-text: var(--color-secondary-text);

/* Menu Logo */
$logo: var(--image-logo);
$text-logo: var(--image-text-logo);

/* Footer Logo */
$footer-logo: var(--image-footer-logo);

/* -------------------------- GENERAL header styling -------------------------- */
.App {
  background: #faf9fc;
  font-family: 'Montserrat', sans;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 500;
}

.App-wrapper {
  text-align: center;
}

header {
  background: #fff;

  .nav-left {
    margin-right: 0.25%;
  }

  .home & {
    background: transparent;

    nav:before {
      background: #fff;
      content: '';
      height: 100%;
      position: absolute;
      width: 50vw;
      top: 0;
      right: 0;
      z-index: 2;

      @media screen and (min-width: 900px) {
        width: 45vw;
      }
      @media screen and (min-width: 1120px) {
        width: 40vw;
      }
    }

    &.hamburgered nav:before {
      display: none;
    }
  }

  #toggle {
    width: 35px;
    cursor: pointer;
    padding: 8px 0;
    position: absolute;
    right: 0px;
    top: 50%;
    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
    transform: translateY(-50%);
    z-index: 100;

    .patty {
      width: 35px;
      height: 2px;
      background: $black;
      border-radius: 2px;
      transition: all 0.3s;
      margin-left: auto;
      position: relative;
      &:before {
        top: -8px;
      }
      &:after {
        top: 8px;
      }
      &:before,
      &:after {
        content: '';
        display: block;
        width: 35px;
        height: 2px;
        background: $black;
        position: absolute;
        z-index: 1;
        transition: all 0.3s;
        border-radius: 2px;
      }
    }
  }

  &.hamburgered {
    background: #fff;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 14;

    div[data-st='st-six'][role='menu'] {
      display: block;
    }

    li a {
      display: block;
      margin: 40px 0;
    }

    #toggle .patty {
      background: none;
      &:before {
        top: 0;
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:after {
        top: 0;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  div[data-st='st-six']:first-child {
    margin-bottom: 0px;
    position: relative;
    a {
      color: $secondary-text;
    }

    div.logo {
      width: 135px;
      height: 48px;
      display: inline-block;
      background: $text-logo;
      background-size: auto 48px;
    }

    .home & {
      & > a:first-child {
        top: 0;
        bottom: initial;
      }
      div.logo {
        background: url(./assets/hac-new-logo.svg) center right no-repeat; //fallback
        background: $logo;
        height: 72px;
        width: 72px;
        @media screen and (min-width: 768px) {
          height: 125px;
          width: 125px;
          background-size: auto 125px;
        }
      }
    }

    h1 {
      width: auto;
      display: inline-block;
      padding-left: 20px;
      margin: 0;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 3px;
      line-height: 1rem;
      text-transform: uppercase;
      text-align: left;
    }
  }

  div[data-st='st-six'][role='menu'] {
    border-top: 2px #eceaea solid;
    display: none;
    height: 100%;
    margin-top: 40px;
    text-align: center;
    width: 100%;
    li {
      text-transform: uppercase;
      list-style-type: none;
      a {
        color: $secondary-text;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        transition: 0.25s all;

        &.hover-none {
          &:hover {
            color: white;
          }
        }
        &:hover {
          color: $gold;
        }
      }
    }
    li:last-child {
      clear: both;
      float: none;
      margin: 0 auto;
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 25rem) {
    div[data-st='st-six'][role='menu'] {
      li:last-child {
        margin-top: 0;
      }
    }
  }

  @media screen and (min-width: 48rem) {
    div[data-st='st-six']:first-child {
      a {
        bottom: 0px;
        position: absolute;
        z-index: 2;
        top: -2px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
      }
      text-align: left;
      width: 150px;
      flex-shrink: 0;
      animation: header-fade 1s linear;
      h1 {
        padding-left: 2rem;
      }
    }

    div[data-st='st-six'][role='menu'] {
      border-top: 0px;
      display: block;
      margin-top: 0px;
      width: calc(100% - 150px);
      animation: header-slide 0.5s linear;

      a span {
        display: none;
      }

      .home & {
        animation: header-fade 0.5s linear;

        nav {
          margin-bottom: 0;
        }
      }
      nav {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: right;
      }
      ul {
        position: relative;
        z-index: 2;
        white-space: nowrap;

        li {
          display: inline-block;
          width: auto;
          margin-right: 1rem;
          letter-spacing: 2.5px;
          letter-spacing: 2.5px;
        }
      }
      ul li:last-child {
        margin-right: 0 !important;

        a {
          border-bottom: 2px solid $black;
          &:hover {
            border-bottom: 2px solid $gold;
          }
        }
        /////
      }
    }

    #toggle {
      display: none;
    }

    @keyframes header-fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes header-slide {
      0% {
        opacity: 0.1;
        transform: translateX(40px);
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }

  @media screen and (min-width: 64rem) {
    div.pw {
      padding-top: 3%;
      margin-bottom: 3% !important;
      div[data-st='st-six']:first-child {
        width: 150px;
      }
      div[data-st='st-six'][role='menu'] {
        width: calc(100% - 150px);
        ul li {
          margin-right: 2rem;
        }

        a span {
          display: inline;
        }
      }
    }
  }

  @media screen and (min-width: 72rem) {
    div.pw {
      padding-top: 1.5%;
      margin-bottom: 1.5% !important;
    }
  }

  @media screen and (min-width: 84rem) {
    div[data-st='st-six']:first-child {
      width: 250px;
    }
    div[data-st='st-six'][role='menu'] {
      width: calc(100% - 250px);
    }
  }
}

/* -------------------------- GENERAL body styling -------------------------- */
.App-wrapper {
  background: #f6f9fc;
}

// modal
.styles_overlay__CLSq- {
  background: rgba(0, 0, 0, 0.3);
}

.styles_modal__gNwvD {
  border-radius: 18px;
  z-index: 10002;
  overflow: hidden;
  width: calc(100% - 30px);

  @media screen and (min-width: 550px) {
    width: calc(100% - 80px);
  }

  @include respond-to('tablet') {
    width: auto;
  }

  button {
    right: 25px;
  }
}

// screenreader text
.sr-text {
  font-size: 0;
  position: absolute;
  left: -99999px;
}

.mb-2 {
  margin-bottom: 2rem;
}
.mb-1 {
  margin-bottom: 1.5rem;
}

/* -------------------------- GENERAL footer styling -------------------------- */

div.dropdown-menu {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  padding: 0.5rem 1.5rem 0 !important;
  margin-bottom: 0 !important;
  border-radius: 2px;
  background: #fff;
  & > * {
    line-height: 48px;
    color: $blue-dark;
    font-size: 1.125rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    &:hover {
      color: $blue-main;
      font-weight: 700;
    }
  }
  & > *:last-child {
    margin-bottom: 0.5rem;
    border-bottom: 0;
  }
}

.MockSelect {
  cursor: pointer;
  position: relative;

  &__arrow {
    fill: #999;
    height: 7px;
    width: 10px;
  }
  &__title {
    align-items: center;
    display: flex;
  }
  &__placeholder {
    flex-grow: 1;
  }
  &__scrim {
    top: 0;
    @include respond-to('tablet') {
      top: 80px;
    }
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
  }
}

.home header {
  & > .pw {
    @media screen and (max-width: 768px) {
      margin-bottom: 5%;
      padding-top: 5%;
    }
  }
  nav {
    @include respond-to('tablet') {
      li a {
        position: relative;
      }
    }
  }
}

//-------- HIRE A COACH CONDITIONAL STYLES -------- //
[data-app='hac'] {
  // NON-HOME PAGE

  .menu-wrapper {
    a.largeBtn[role='button'] {
      margin-top: 0;
      border-bottom: none;
      &:hover {
        border-bottom: none;
        color: white;
      }
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 5%;
      padding-top: 5%;
    }
  }

  div[data-st='st-six']:first-child {
    div.logo {
      &[data-app='hac'] {
        position: relative;

        background-size: 130px;
        @media screen and (min-width: 768px) {
          background-size: 130px;
        }
      }
    }
  }

  // HOME PAGE SPECIFIC
  &.home {
    .nav-left {
      height: 75px;
    }

    nav[data-app='hac'] {
      li {
        a {
          @media screen and (min-width: 48rem) {
            color: white;
          }
        }
        a.largeBtn[role='button'] {
          background: white;
          color: black; //fallback
          color: $black;
          padding: 1rem 1rem;
          border-bottom: none;
        }
      }
    }

    header {
      position: absolute;
      width: 100%;

      nav[data-app='hac'] {
        &:before {
          background: transparent;
        }
      }

      #toggle {
        .patty {
          &[data-app='hac'] {
            background: white;
            &:before,
            &:after {
              background: white;
            }
          }
        }
      }

      // MOBILE NAVE MENU OPEN
      &.hamburgered {
        position: fixed;
        z-index: 125;

        #toggle {
          .patty {
            &[data-app='hac'] {
              background: none;
              &:before,
              &:after {
                background: $black;
              }
            }
          }
        }
      }

      div[data-st='st-six']:first-child {
        div.logo {
          &[data-app='hac'] {
            height: 72px;
            width: 72px;
            background-size: auto 72px;
            position: relative;
            @media screen and (min-width: 768px) {
              height: 125px;
              width: 125px;
              background-size: auto 125px;
              display: none;
            }
            background: $text-logo;
          }
        }
      }
    }
  }
}

.Default-spacing {
  margin: 1.5rem 0;
}

.Input {
  width: 100%;
  font-size: 0.9rem;
  font-family: inherit;
  line-height: 1;
  height: auto;
  text-overflow: ellipsis;
  transition: 0.3s all;
  border: none;

  &::placeholder {
    font-weight: 400;
    // font-style: italic;
    color: $black;
  }
}

.Icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;

  &--search {
    @extend .Icon;
    background: center / contain no-repeat url(./assets/search_icon.svg);
  }
  &--location {
    @extend .Icon;
    background: center / contain no-repeat url(./assets/location_icon.svg);
  }
  &--chevron-down {
    @extend .Icon;
    background: center / contain no-repeat url(./assets/chevron-down_icon.svg);
  }
  &--filter {
    @extend .Icon;
    background: center / contain no-repeat url(./assets/filter_icon.svg);
  }
  &--reload {
    @extend .Icon;
    background: center / contain no-repeat url(./assets/reload.svg);
  }
}

.Flex {
  display: flex !important;

  &--center {
    @extend .Flex;
    align-items: center;
  }

  &--justify-center {
    @extend .Flex;
    justify-content: center;
  }

  @for $i from 1 through 4 {
    &--#{$i} {
      flex: $i;
    }
  }
}

.react-responsive-modal-modal {
  font-family: 'Montserrat';

  .react-responsive-modal-closeButton {
    top: 25px;
    right: 15px;

    @include respond-to('tablet') {
      top: 25px;
      right: 25px;
    }
  }

  &.profileModal {
    width: calc(100% - 0rem);
    max-width: 1200px;
    margin: 0;
    padding: 1.2rem 0.5rem;
    background: transparent;
    box-shadow: none;

    @include respond-to('tablet') {
      width: calc(100% - 2.4rem);
      padding: 1.2rem;
    }

    .close-button {
      height: 45px;
      width: 20px;
      border-radius: 50%;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
      }

      @include respond-to('tablet') {
        height: 50px;
        width: 50px;
        background: $secondary-text;
      }
    }

    .modal-content {
      height: auto;
      border-radius: 18px;
      background: white;
    }

    .button-wrapper {
      min-height: 4rem;
      background: transparent;
    }

    .profile-wrapper {
      background: white;
      border-radius: 18px;
      overflow: scroll;
      max-height: 85vh;
      min-height: 85vh;

      border-radius: 18px;
    }

    div.Profile {
      h3 {
        border-bottom: 0;
        margin-bottom: 0.5rem;
      }

      a.quietBtn[role='button'] {
        color: $secondary-text;

        &:hover {
          color: white;
        }
      }

      section.content {
        aside.sidebar {
          @media screen and (min-width: 768px) {
            margin-top: -190px;
          }
        }
      }
    }
  }
}

.text-with-check {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  p {
    margin-bottom: 0;
    font-weight: bold;
    // white-space: nowrap;
  }

  img {
    height: 1rem;
    margin-right: 1rem;
  }
}

/* -------------------------- Specific page imports -------------------------- */
@import './modules/home/_home.scss';
@import './modules/home/_homeHac.scss';
@import './modules/profile/_profile.scss';
@import './modules/search/_search.scss';
@import './modules/shared/_location-input.scss';
@import './modules/shared/_button.scss';
