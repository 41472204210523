/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* -------------------------- Hire A Coach Theme -------------------------- */
:root [data-app='hac'] {
  /* Theme Colors */
  --color-black: #2d3354;
  --color-black-light: #363d63;
  --color-gold: #ee2362;
  --color-gold-light: #cfc393;
  --color-header-text: #181c21;
  --color-paragraph-text: #181c21;
  --color-secondary-text: #181c21;

  /* Menu Logo */
  --image-logo: url(./assets/hac-new-logo.svg) center right no-repeat;
  --image-text-logo: url(./assets/hac-new-logo-black.svg) center right no-repeat;

  /* Footer Logo */
  --image-footer-logo: url(./assets/hac-new-logo.svg) center right no-repeat;
}

/* -------------------------- Marketing Made Simple Theme -------------------------- */
:root [data-app='mms'] {
  /* Theme Colors */
  --color-black: #000;
  --color-black-light: rgba(0, 0, 0, 0.9);
  --color-gold: #9a875e;
  --color-gold-light: #cfc393;
  --color-header-text: #2b2d3a;
  --color-paragraph-text: #181920;
  --color-secondary-text: #13141b;

  /* Menu Logo */
  --image-logo: url(./assets/mms-logo.svg) center right no-repeat;
  --image-text-logo: url(./assets/mms-text-only-logo.svg) center right no-repeat;

  /* Footer Logo */
  --image-footer-logo: url(./assets/cym_logo_dark.svg) center right no-repeat;
}

.App {
  @apply text-black;
}

p,
li,
label {
  @apply text-base tracking-wide text-black;
}

label {
  @apply block mb-1 font-semibold;
}

input,
textarea {
  @apply w-full px-4 py-3 text-base tracking-wide placeholder-gray-400 border-none rounded-sm bg-gray-50 focus:outline-dark;
}

.btn {
  @apply inline-block px-8 py-4 font-bold tracking-wide text-center no-underline uppercase cursor-pointer rounded-btn;
}

.btn--primary {
  @apply text-white bg-cbPink;
}

.btn--primary-ghost {
  @apply text-black transition-all duration-200 border border-black border-solid hover:text-white hover:bg-black;
}

ul {
  @apply leading-6 text-black list-none;
}

.rc-tooltip {
  font-family: 'Montserrat', sans;
}
