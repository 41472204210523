//GENERAL STYLES

body {
  overflow-x: hidden;
}

.certification-card {
  min-height: 700px;
  @media (max-width: 1024px) {
    min-height: 500px;
  }
}

.dark-radial-gradient {
  background: #0a0a0a;
  background: linear-gradient(45deg, #0a0a0a, #373737 95%, #484848);
}
.light-text-gradient {
  padding-bottom: 0.2rem;
  background: #67666b;
  background: linear-gradient(to right, #67666b 31%, #140f2e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-lavender {
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 0%, #cfcaee 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
}

.dark-text-gradient {
  padding-bottom: 0.2rem;
  background: #f1eee9;
  background: linear-gradient(to right, #f1eee9 31%, #d6cbb7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-navy {
  background: linear-gradient(110deg, #222f44 22.91%, #1f293d 65.63%, #090a11 82.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
}

.line-gradient {
  height: 5px;
  align-self: start;
  border-radius: 10px;
  background: linear-gradient(to right, #181c21, #ee3662);
}
.dark-radial-gradient {
  background: rgb(10, 10, 10);
  background: linear-gradient(45deg, rgba(10, 10, 10, 1) 0%, rgba(55, 55, 55, 1) 95%, rgba(72, 72, 72, 1) 100%);
}
[data-app='hac'] {
  &.home {
    .cbBtn {
      background: #ee3662;
    }

    .cbBtn-pill {
      background: #ee3662;
    }

    .header-dark {
      color: $black;
    }

    a.largeBtn[role='button'] {
      background: #ee3662;
    }

    h2 {
      font-size: 3.5rem;
      line-height: 3.5rem;
      span {
        color: $gold;
      }

      @media only screen and (max-width: 650px) {
        font-size: 2.5rem;
      }
    }

    .mb-0 {
      margin-bottom: 0 !important;
    }

    .pd-0 {
      padding: 0 !important;
    }
  }

  a.largeBtn[role='button'] {
    background: #ee3662;
    color: white;
    padding: 1rem 1rem;
    border-bottom: none;
  }
}

// HERO SECTION
section.hero {
  position: relative;
  z-index: 1;

  .hero-content,
  .hero-content-bottom,
  .hero-content-triangle {
    margin-left: 2.7rem;

    @media screen and (max-width: 992px) {
      margin-left: 0;
    }
  }

  .hero-wrapper {
    min-height: 52rem;
    position: relative;
    background: white;
    overflow: hidden;

    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%;

      @media screen and (max-width: 992px) {
        padding-bottom: 0;
        height: 100%;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 992px) {
          object-fit: cover;
        }
      }
    }
    .hero-image {
      height: 38rem;
      position: relative;

      background: url(../../assets/hac-header-image.jpg) center right no-repeat;
      background-size: cover;

      @media only screen and (max-width: 1176px) {
        background-position: center center;
      }
      .clip-paths {
        width: 100%;
        height: 10rem;
        position: absolute;
        bottom: 0px;
      }

      .white-polygon {
        z-index: 25;
        background: white;
        clip-path: polygon(70% 30%, 100% 85%, 100% 100%, 0% 100%);
        transform: translateZ(0);

        @media screen and (max-width: 992px) {
          display: none;
        }

        &[data-ie='ie'] {
          display: none;
        }

        .hero-content-triangle {
          height: 10rem;
          width: 450px;
          position: absolute;
          background: #181c21;

          @media screen and (max-width: 992px) {
            width: 100%;
            background: white;
          }
        }
      }

      .white-triangle {
        z-index: 15;
        clip-path: polygon(100% 0%, 0 100%, 100% 100%);
        transform: translateZ(0);

        .gold-triangle {
          width: 30%;
          height: 300px;
          background: linear-gradient(to right, #c42c51, #ee3662);
          position: absolute;
          right: 0px;
          transform: rotate(0deg);
          bottom: 1px;

          @media screen and (max-width: 992px) {
            display: none;
          }

          &[data-ie='ie'] {
            display: none;
          }
        }
      }

      .hero-content {
        height: 38rem;
        width: 450px;
        position: relative;
        top: 0;
        padding: 48px 2rem 0;
        background: #181c21;

        @media only screen and (max-width: 992px) {
          width: 100%;
          background: #131936dd;
          padding: 48px 5% 0;
        }

        @media only screen and (max-width: 40rem) {
          padding: 40px 8% 0;
        }

        .logo {
          background: url(../../assets/hac-new-logo.svg); // fallback
          background: $logo;
          height: 40px;

          @media screen and (min-width: 768px) {
            margin-bottom: 4rem;
          }
        }

        h1 {
          padding: 0 0 0.75rem 0;
          color: white;
          font-size: 3rem;
          line-height: 3.5rem;

          @media only screen and (max-width: 992px) {
            padding: 2rem 0 0.75rem 0;
          }

          @media only screen and (max-width: 650px) {
            font-size: 2.5rem;
          }

          span {
            color: $gold;
          }
        }

        h5 {
          color: white;
          line-height: 1.5rem;
          font-size: 1.1rem;
          font-weight: normal;
        }

        .cta {
          margin-left: 0;
          position: relative;
          z-index: 900;
        }
      }
    }
    .hero-content-bottom {
      width: 450px;
      position: absolute;
      padding: 2.5rem 2rem 2rem;
      left: 0px;
      background: #181c21;
      z-index: 0;

      @media screen and (max-width: 992px) {
        width: 100%;
        background: white;
        position: relative;
      }

      p {
        color: white;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 1rem;

        @media screen and (max-width: 992px) {
          color: $black;
          text-align: center;
          font-size: 1.25rem;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    .reasons {
      width: calc(100% - (450px + 2.7rem));
      margin-left: calc(450px + 2.7rem);
      margin-right: 0;
      padding: 0 2rem;
      position: relative;
      top: -10px;
      z-index: 50;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      &[data-ie='ie'] {
        top: 50px;
      }

      @media screen and (max-width: 992px) {
        width: 85%;
        margin: 2rem auto;
        padding: 0 1rem;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
      }

      .reason {
        flex: 0 0 33.333333%;
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 0 0 30px;
        padding: 3px;
        border-radius: 6px;

        img {
          height: 4rem;
          width: 4rem;
          //margin-right: 24px;
        }

        @media screen and (max-width: 992px) {
          min-width: 50%;
          padding: 0 1rem;
        }

        @media screen and (max-width: 768px) {
          min-width: 85%;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 0.95rem;
          line-height: 1.3rem;
          text-align: left;
          width: 90%;
          //padding: 0 100px 0 0;

          @media screen and (max-width: 768px) {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

//10 WAYS TO MAKE MORE MONEY SECTION
section.more-money {
  background: white;

  .heading {
    margin: 0 0 1rem;
  }

  h3 {
    max-width: 800px;
    margin: auto;
    text-align: center;
  }

  a.largeBtn[role='button'] {
    margin: 3rem auto;
  }

  [data-st='st-row'] {
    width: 85%;

    .ways-column {
      margin: 0 2rem 0 0;

      @include respond-to('desktop') {
        margin: 0 4rem 0 0;
      }

      .way {
        display: flex;
        align-items: center;
        margin: 0 0 3rem;

        img {
          height: 6rem;
        }

        p {
          padding: 0 0 0 2rem;
          font-weight: 500;
          max-width: 500px;
        }
      }

      &.two {
        margin: 0;
        .way {
          display: flex;
          justify-content: flex-start;

          @include respond-to('tablet') {
            // justify-content: flex-end;
          }
        }
      }
    }
  }
}

//WHAT YOU GET SECTION
section.what-you-get {
  position: relative;
  background: rgb(24, 28, 33);

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100vw 150px 0;
    border-color: transparent white transparent transparent;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100vw 0 0;
    border-color: transparent white transparent transparent;
  }

  .pw {
    padding-top: 90px;
  }

  .content {
    padding-top: 90px;
    margin: 0;

    h2,
    p {
      text-align: left;
      margin-bottom: 2rem;
    }

    h2 {
      .desktop-only {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .image-right {
    width: 450px;
    position: relative;
    float: right;
    right: -9%;
    margin: 0;

    @media screen and (min-width: 40rem) {
      right: -6%;
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }

    @media screen and (min-width: 84rem) {
      right: -5%;
    }

    @media screen and (min-width: 1600px) {
      right: -0%;
      position: absolute;
      width: 450px;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
      top: 100px;
    }
  }

  .cta {
    margin-left: 0;
  }
}

// GROW YOUR BUSINESS SECTION
section.grow {
  position: relative;
  overflow: hidden;
  background: white;

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -38px;
    border-style: solid;
    border-width: 0 100vw 150px 0;
    border-color: transparent white transparent transparent;
  }

  h2 {
    text-align: center;
    padding-left: 0rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 800;
  }

  [data-st='st-four'] {
    padding-right: 2rem;
    margin-right: 8%;
  }

  .img-wrapper {
    height: 100px;
    margin-bottom: 2rem;
    display: flex;
    justify-items: center;
  }
  .cta {
    padding: 0;
    margin-bottom: 0;

    a.largeBtn[role='button'] {
      margin: 3rem auto;
    }
  }

  .bottom {
    height: 7rem;
    z-index: 100;
  }

  .gold-tab {
    height: 7rem;
    width: 300px;
    background: linear-gradient(to right, #e4c87d, #b0864b);
    position: absolute;
    bottom: 0;
  }
}

// WITH A COACH SECTION
section.with-a-coach {
  position: relative;
  background: white;

  &:before {
    content: '';
    position: absolute;
    top: -150px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 150px 100vw;
    border-color: transparent transparent white transparent;
  }

  h2 {
    text-align: center;
    padding-left: 0rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 800;
    text-align: left;
  }

  p {
    text-align: center;
    max-width: 600px;
    margin: auto;
  }

  a.largeBtn[role='button'] {
    &.cta {
      margin: 3rem auto;
    }
  }

  [data-st='st-row'] {
    flex-wrap: wrap;
    justify-content: flex-start;
    // border: 1px solid black;
  }

  [data-st='st-four'] {
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    margin: 0 0px 0 0;
    width: calc(33.33%);
    // border: 1px solid black;

    @media screen and (max-width: 992px) {
      width: 50%;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
      // justify-content: left;
    }
  }

  .reason {
    min-width: 275px;
    max-width: 275px;
    margin: 0 0 30px;
    padding: 0px;
    display: flex;

    h3 {
      padding-left: 2rem;
      margin: 0;
      font-weight: 800;
    }
  }
}

section.are-you-a-coach {
  position: relative;
  background: linear-gradient(to right, #181c21, #ee3662);

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100vw 100px 0;
    border-color: transparent white transparent transparent;
  }

  [data-st='st-six'] {
    margin: 0;
    width: 50%;
  }

  .content {
    padding-top: 90px;
    margin: 0;

    h2,
    p {
      text-align: left;
      margin-bottom: 2rem;
    }

    h2 {
      padding: 2rem 0;
      color: #131936;

      span {
        color: white;
      }
    }

    p {
      color: white;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    a.largeBtn[role='button'] {
      margin: 4rem 0 3rem;
      background: white;
      color: #b4a269;
      color: $gold;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .image-right {
    width: 450px;
    position: relative;
    float: right;
    right: -9%;
    margin: 0;

    @media screen and (min-width: 40rem) {
      right: -6%;
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }

    @media screen and (min-width: 84rem) {
      right: -5%;
    }

    @media screen and (min-width: 1600px) {
      right: -0%;
      position: absolute;
      width: 450px;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
      top: 100px;
    }
  }
}
